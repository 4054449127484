import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';

async function getResponse(username) {
    var search;
    var response;

    const API_URL = 'https://api.rentierstate.com/';
    //const API_URL = 'http://127.0.0.1:3000/';

    try {

        search = new URLSearchParams({
            username: username,
        });

        const session = await fetchAuthSession();   // Fetch the authentication session

        var userInfoHeaders = new Headers();
        userInfoHeaders.append("Authorization", session.tokens.idToken.toString());

        response = await fetch(API_URL + 'tasks/user_info?' + search, { headers: userInfoHeaders });

    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
    return response.json();
}

async function currentAuthenticatedUser() {
    try {
        const { username, userId, signInDetails } = await getCurrentUser();
        return username;
    } catch (err) {
        console.log(err);
    }
}

export async function fetchUserInfo() {

    var username = await currentAuthenticatedUser();
    var response = await getResponse(username);

    return response;
};

export async function fetchOrgUsers(org_id) {
    var search;
    var response;

    const API_URL = 'https://api.rentierstate.com/';
    //const API_URL = 'http://127.0.0.1:3000/';

    if (typeof (org_id) == 'undefined') {
        return [];
    }

    try {

        search = new URLSearchParams({
            organization: org_id,
        });

        const session = await fetchAuthSession();   // Fetch the authentication session

        var userInfoHeaders = new Headers();
        userInfoHeaders.append("Authorization", session.tokens.idToken.toString());



        response = await fetch(API_URL + 'tasks/user_info?' + search, { headers: userInfoHeaders });

    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
    return response.json();
}