import React, { useRef, useCallback, useState, useContext } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { ExpandableCalendar, AgendaList, CalendarProvider, WeekCalendar } from 'react-native-calendars';
import { useQuery } from '@tanstack/react-query';
import { getMarkedDates } from './agendaItems';
import AgendaItem from './AgendaItem';
import { getTheme, themeColor, lightThemeColor } from './theme';
import testIDs from './testIDs';
import { fetchUserInfo } from '../../utils/getUserInfo';
import fetchApiTodos from '../../utils/getAPITodos.js';
import { UserContext } from '../../../App.js';

//const leftArrowIcon = require('../../assets/images/previous.png');
const leftArrowIcon = require('../../assets/images/emoji1.png');
const rightArrowIcon = require('../../assets/images/next.png');

const ExpandableCalendarTab = (props) => {

    const user = useContext(UserContext)

    const { weekView } = props;
    const marked = useRef(getMarkedDates());
    const theme = useRef(getTheme());
    const todayBtnTheme = useRef({
        todayButtonTextColor: themeColor
    });

    const onDateChanged = useCallback((date, updateSource) => {
        console.log('ExpandableCalendarScreen onDateChanged: ', date, updateSource);
    }, []);

    const onMonthChange = useCallback(({ dateString }) => {
        console.log('ExpandableCalendarScreen onMonthChange: ', dateString);
    }, []);

    const renderItem = useCallback(({ item }) => {
        return <AgendaItem item={item} />;
    }, []);

    const id_type = "user"
    const id_value = user.id
    const start = "2024-07-01"
    const end = "2025-03-31"
    const cal = true

    var result = { "status": "pending" }

    result = useQuery({
        queryKey: ['todos', id_type, id_value, start, end, cal,],
        queryFn: () => fetchApiTodos(id_type, id_value, start, end, cal,),
    });
    console.log(result);

    return (
        <View style={{ flex: 1 }}>
            {result.status === 'pending' ? (
                <ActivityIndicator />
            ) : (
                <CalendarProvider
                    //date={result.data[0]?.title}
                    date={result.data[0]?.title}
                    onDateChanged={onDateChanged}
                    onMonthChange={onMonthChange}
                    showTodayButton
                    // disabledOpacity={0.6}
                    theme={todayBtnTheme.current}
                // todayBottomMargin={16}
                >
                    {weekView ? (
                        <WeekCalendar testID={testIDs.weekCalendar.CONTAINER} firstDay={1} markedDates={marked.current} />
                    ) : (
                        <ExpandableCalendar
                            testID={testIDs.expandableCalendar.CONTAINER}
                            // horizontal={false}
                            hideArrows={false}
                            // disablePan
                            // hideKnob
                            // initialPosition={ExpandableCalendar.positions.OPEN}
                            // calendarStyle={styles.calendar}
                            // headerStyle={styles.header} // for horizontal only
                            // disableWeekScroll
                            theme={theme.current}
                            // disableAllTouchEventsForDisabledDays
                            firstDay={1}
                            markedDates={marked.current}
                            leftArrowImageSource={leftArrowIcon}
                            rightArrowImageSource={rightArrowIcon}
                        // animateScroll
                        // closeOnDayPress={false}
                        />
                    )}
                    <AgendaList
                        sections={result.data}
                        renderItem={renderItem}
                        // scrollToNextEvent
                        sectionStyle={styles.section}
                    // dayFormat={'yyyy-MM-d'}
                    />
                </CalendarProvider>
            )}
        </View>
    );
};

export default ExpandableCalendarTab;

const styles = StyleSheet.create({
    calendar: {
        paddingLeft: 20,
        paddingRight: 20
    },
    header: {
        backgroundColor: 'lightgrey'
    },
    section: {
        backgroundColor: lightThemeColor,
        color: 'grey',
        textTransform: 'capitalize'
    }
});