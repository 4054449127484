import { fetchAuthSession } from '@aws-amplify/auth';

async function fetchProjects(user_id, start, end) {
    var search;
    var response;

    const API_URL = 'https://api.rentierstate.com/'
    //const API_URL = 'http://127.0.0.1:3000/';

    try {

        if (typeof (user_id) == 'undefined') {
            return [];
        }

        search = new URLSearchParams({
            user_id: user_id,
            start: start,
            end: end,
        });

        const session = await fetchAuthSession();   // Fetch the authentication session

        var projectHeaders = new Headers();
        projectHeaders.append("Authorization", session.tokens.idToken.toString());

        response = await fetch(API_URL + 'tasks/projects?' + search, { headers: projectHeaders });

    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
    return response.json();
}

export default fetchProjects;