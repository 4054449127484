import { StyleSheet, Text } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import ToDoList from './ToDoList';
import ProjectToDoList from './ByProjectList';

const Tab = createMaterialTopTabNavigator();

function NavTabs() {
  return (
    <Tab.Navigator
      initialRouteName="All"
      //tabBarPosition="bottom"
      screenOptions={{
        tabBarActiveTintColor: '#f0ffff',
        tabBarInactiveTintColor: '#d3d3d3',
        tabBarLabelStyle: { fontSize: 12 },
        tabBarStyle: { backgroundColor: '#483D8B' },
      }}
    >
      <Tab.Screen
        name="All"
        component={ToDoList}
        options={{ tabBarLabel: 'All' }}
      />
      <Tab.Screen
        name="ByProject"
        component={ProjectToDoList}
        options={{ tabBarLabel: 'By Project' }}
      />
      <Tab.Screen
        name="Mine"
        component={ToDoList}
        options={{ tabBarLabel: 'Mine' }}
      />
    </Tab.Navigator>
  );
};

export default function ToDoTab() {
  return (
    <SafeAreaProvider style={styles.container}>

      <NavTabs />

    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#d3d3d3',
  },
});
