import { fetchAuthSession } from '@aws-amplify/auth';

async function fetchApiTodos(id_type, id_value, start, end, cal) {

    var search;

    const API_URL = 'https://api.rentierstate.com/'
    //const API_URL = 'http://127.0.0.1:3000/';

    try {
        if (typeof (id_value) == 'undefined') {
            return {};
        }

        if (id_type === "project") {
            search = new URLSearchParams({
                start: start,
                end: end,
                project_id: id_value,
                cal: cal,
            });
        } else if (id_type === "user") {
            search = new URLSearchParams({
                start: start,
                end: end,
                user_id: id_value,
                cal: cal,
            });
        }

        const session = await fetchAuthSession();   // Fetch the authentication session

        var todoHeaders = new Headers();
        todoHeaders.append("Authorization", session.tokens.idToken.toString());

        const response = await fetch(API_URL + 'tasks/tasks?' + search, { headers: todoHeaders });

        const responseData = await response.json();

        return responseData

    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};

export default fetchApiTodos;