import { useCallback, useState, useEffect } from 'react';
import { StyleSheet, View, Text, Pressable, Button } from 'react-native';
//import ProfileIcon from '../../assets/images/person.svg'
import ProfileIcon from '../../assets/images/person.svg'
import Task10 from '../../assets/images/clock_loader_10.svg'
import Task20 from '../../assets/images/clock_loader_20.svg'
import Task40 from '../../assets/images/clock_loader_40.svg'
import Task60 from '../../assets/images/clock_loader_60.svg'
import Task80 from '../../assets/images/clock_loader_80.svg'
import Task90 from '../../assets/images/clock_loader_90.svg'

import TaskModal from './TaskModal';

const Task = ({ user_id, owner_code, owner_icon_color, task_id, task_name, project_name, due_date, completion_percent, completion_target, detailsStatus, details, projectStatus, projects, usersStatus, users, isCal = false }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const itemPressed = useCallback(() => {
        Alert.alert(item.title);
    }, []);

    const clockIcons = {
        0.1: Task10,
        0.2: Task20,
        0.4: Task40,
        0.6: Task60,
        0.8: Task80,
        0.9: Task90,
    };

    const parseDate = (dateStr) => {
        const options = {
            month: "numeric",
            day: "numeric",
        };

        var dateTimestamp = Date.parse(dateStr);
        var dateObject = new Date(dateTimestamp);
        return dateObject.toLocaleDateString("en-US", options)
    };

    var detail = {}

    if (detailsStatus === 'success') {
        detail = details.filter(detail => { return detail.task_id === task_id; });
    }

    return (
        < View style={styles.container} >
            <Pressable style={styles.container} onPress={toggleModal}>
                <View style={styles.task}>
                    <View style={styles.person_box}>
                        <View><img src={ProfileIcon} style={{ color: owner_icon_color }} alt="profile icon"></img></View>
                        <View><Text>{owner_code}</Text></View>
                    </View>
                    <View style={styles.task_desc}>
                        <View><Text style={styles.task_name}>{task_name}</Text></View>
                        <View><Text style={styles.project_name}>{project_name}</Text></View>
                    </View>
                    <View style={styles.task_status}>
                        <View><img src={clockIcons[completion_percent]} style={{ color: owner_icon_color }} alt={completion_percent}></img></View>
                        <View><Text style={styles.project_name}>{parseDate(due_date)}</Text></View>
                    </View>
                    {isCal &&
                        <View>
                            <Pressable onPress={itemPressed} style={styles.item} testID={task_id}>
                                <View style={styles.itemButtonContainer}>
                                    <Button color={'grey'} title={'Info - Trae'} onPress={toggleModal} />
                                </View>
                            </Pressable>
                            {
                                projectStatus === 'success' && detailsStatus === 'success' && isModalVisible && <TaskModal
                                    user_id={user_id}
                                    taskDetail={details.filter(detail => { return detail.task_id === task_id; })}
                                    //taskDetail={detail}
                                    toggleModal={toggleModal}
                                    detailsStatus={detailsStatus}
                                    projects={projects}
                                    users={users}
                                />
                            }
                        </View>
                    }
                </View >
            </Pressable>
            {
                usersStatus === 'success' && projectStatus === 'success' && detailsStatus === 'success' && isModalVisible && <TaskModal
                    user_id={user_id}
                    taskDetail={details.filter(detail => { return detail.task_id === task_id; })[0]}
                    toggleModal={toggleModal}
                    detailsStatus={detailsStatus}
                    projects={projects}
                    users={users}
                />
            }
        </View>
    );
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%',
        borderColor: 'white',
        borderWidth: 1,
    },
    task: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        rowGap: 3,
        columnGap: 3,
        backgroundColor: '#d3d3d3',
        borderTopColor: 'white',
        borderTopWidth: 1,
    },
    person_box: { flex: 1, flexDirection: 'column', alignItems: 'center', alignSelf: 'center' },
    task_desc: { flex: 4, flexDirection: 'column', alignItems: 'flex-start' },
    task_status: { flex: 1, flexDirection: 'column', alignItems: 'center', alignSelf: 'center' },
    task_name: {
        fontSize: 18,
        margin: 5,
        color: '#363737',
    },
    project_name: {
        fontSize: 12,
        margin: 2,
        color: '#000000',
    },
});

export default Task;