import { useState } from 'react';
//import memoize from "memoize-one";
import { View, StyleSheet, Modal, Text, TextInput, TouchableOpacity } from 'react-native';
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query';
//import Dropdown from 'react-native-input-select';
//import { Input } from "@material-ui/core"
import { fetchAuthSession } from '@aws-amplify/auth';

const Comment = ({ author, date, comment }) => {

    return (
        < View >
            <View style={styles.comment}>
                <View style={styles.author_box}>
                    <View><Text>{author} on {date}</Text></View>
                </View>
                <View style={styles.comment}>
                    <View><Text style={styles.comment_text}>{comment}</Text></View>
                </View>
            </View >
        </View>
    );
};


// next step,
// put in background request for task detail in ToDoList
// (and cal). Make it so that that state is managed there and
// passed here into the task_detail for the task clicked on (by id)
// bonus, make it so that if there is a click before the detail is
// returned from the API and loaded into state, we get a spinning wheel
// vs. an error
const TaskModal = ({ user_id, taskDetail, toggleModal, detailsStatus, projects, users }) => {

    const toggleMe = () => {
        toggleModal?.()
    };

    const renderComment = ({ item }) => {
        return (
            <Comment
                id={item.id}
                author={item.author}
                date={item.date}
                comment={item.content}
            />
        );
    };

    const [isEditing, setIsEditing] = useState(false);

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const onSubmit = async (data) => {
        console.log(data);
        mutate(data);
    };

    const { mutate, isLoading, error } = useMutation(
        {
            mutationFn: async (data) => {
                const session = await fetchAuthSession();   // Fetch the authentication session

                var updateHeaders = new Headers();
                updateHeaders.append("Authorization", session.tokens.idToken.toString());
                updateHeaders.append('Content-Type', 'application/json');

                const response = await fetch('https://api.rentierstate.com/tasks/' + taskDetail.task_id, {
                    //const response = await fetch('http://127.0.0.1:3000/tasks/' + taskDetail.task_id, {
                    method: 'PUT',
                    headers: updateHeaders,
                    body: JSON.stringify(data),
                });
                toggleMe();
                return response.json();
            },
            onSuccess: (data) => console.log('Form submitted successfully:', data),
            onError: (error) => console.error('Error submitting form:', error),
        }
    );

    const { control, handleSubmit, formState: { errors } } = useForm(
        {
            defaultValues: {
                project: projects[0],
                task_name: taskDetail.task_name,
                owner_name: taskDetail.owner_id,
                due_date: taskDetail.due_date,
            },
        }
    );

    const project_options = projects.map((project) => (
        { label: project.project_name, value: project.id }
    ));

    const owner_options = users.map((user) => (
        { label: user.username, value: user.id }
    ));

    if (detailsStatus === "success") {

        return (
            <Modal
                transparent={true}
                animationType='slide'
                onRequestClose={toggleMe}
            >
                <View style={styles.modal}>

                    <View style={styles.modalWrapper}>

                        <View style={styles.modalHeader}>
                            <View style={styles.modalHeaderContent}><Text>Please modify the task and hit submit below</Text></View>
                            <TouchableOpacity onPress={() => toggleMe()}>
                                <Text style={styles.modalHeaderCloseText}>X</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.formContainer}>
                            <form onSubmit={handleSubmit(onSubmit)} style={styles.formStyle}>


                                <View style={styles.input}>
                                    <Text>Task name: </Text>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TextInput
                                                //placeholder={ownerName}
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}
                                            />
                                        )}
                                        name="task_name"
                                    />
                                </View>
                                <View style={styles.input}>
                                    <Text>Owner user name: </Text>
                                    <Controller
                                        name="owner_id"
                                        control={control}
                                        defaultValue="" // Set initial value
                                        render={({ field }) => (
                                            <select {...field}>
                                                {owner_options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />
                                </View>
                                <View style={styles.input}>
                                    <Text>Due date: </Text>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TextInput
                                                //placeholder={dueDate}
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}
                                            />
                                        )}
                                        name="due_date"
                                    />
                                </View>
                                <View style={styles.input}>
                                    <Text>Project: </Text>
                                    <Controller
                                        name="project_id"
                                        control={control}
                                        defaultValue="" // Set initial value
                                        render={({ field }) => (
                                            <select {...field}>
                                                {project_options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />
                                </View>
                                <input type="submit" />

                            </form>
                        </View>
                    </View>
                </View>
            </Modal>
        );
    }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    modal: {
        flex: 1,
        alignItems: 'center',
    },
    modalWrapper: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#d3d3d3',
        padding: 5,
        width: '75%'
    },
    modalHeader: {
        flexDirection: "row",
    },
    modalHeaderContent: {
        flexGrow: 1,
    },
    modalHeaderCloseText: {
        textAlign: "center",
        paddingLeft: 5,
        paddingRight: 5
    },
    taskName: {
        fontSize: 18,
        color: '#363737',
    },
    formContainer: {
        width: '100%',
        maxWidth: 300,
        alignItems: 'stretch',
    },
    input: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'gray',
        backgroundColor: 'white',
        padding: 10,
        marginBottom: 10,
    },
    buttons: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        alignSelf: 'center',
        justifyContent: 'space-around',
        width: '40%',

    },
    commentWrapperStyle: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: '#ffffff',
        width: '100%',
        borderColor: 'grey',
        borderWidth: 1,
    },
    comment: {
        flex: 1,
        width: '100%',
        justifyContent: 'stretch',

    },
    author_box: {
        fontSize: 12,
        margin: 2,
        width: '100%',
    },
    comment_text: {
        fontSize: 14,
        margin: 2,
        width: '100%',
    },
    flatList: {
        flex: 1,
        width: '100%',
        alignItems: 'flex-start',
    },
});

export default TaskModal;