import { useState } from 'react';
import { View, Text, StyleSheet, FlatList, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Accordion from 'react-native-collapsible/Accordion';
import Icon from 'react-native-vector-icons/FontAwesome';

import todos from './todos_by_project.json';
import Task from './Task.js';

const ProjectToDoList = () => {
    const [activeSections, setActiveSections] = useState([]);

    const renderTask = ({ item }) => {
        return (
            <Task
                owner_code={item.owner_code}
                owner_icon_color={item.owner_icon_color}
                task_id={item.task_id}
                task_name={item.task_name}
                project_name={item.project_name}
                due_date={item.due_date}
                completion_percent={item.completion_percent}
                completion_target={item.completion_target}
            />
        );
    };

    function renderContent(todo, _, isActive) {
        return (
            <View style={styles.accordBody}>
                <FlatList style={styles.flatList}
                    data={todo.project_array}
                    renderItem={renderTask}
                />
            </View>
        );
    }


    function renderHeader(project, _, isActive) {
        return (
            <View style={styles.accordHeader}>
                <Text style={styles.accordTitle}>{project.project_name}</Text>
                <Icon name={isActive ? 'chevron-down' : 'chevron-up'}
                    size={20} color="#bbb" />
            </View>
        );
    };

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView
                contentInsetAdjustmentBehavior="automatic"
                style={styles.container}>
                <Accordion
                    align="bottom"
                    sections={todos}
                    activeSections={activeSections}
                    renderHeader={renderHeader}
                    renderContent={renderContent}
                    onChange={(todos) => setActiveSections(todos)}
                    sectionContainerStyle={styles.accordContainer}
                    expandMultiple={true}
                />
            </ScrollView>
        </SafeAreaView>
    );
};



const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',

    },
    flatList: {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
    },
    accordContainer: {
        paddingBottom: 4,
        width: '100%',
    },
    accordHeader: {
        padding: 12,
        backgroundColor: '#666',
        color: '#eee',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    accordTitle: {
        fontSize: 20,

    },
    accordBody: {
        width: '100%',
        backgroundColor: '#d3d3d3',
    },
    textSmall: {
        fontSize: 16
    },
    separator: {
        height: 12
    }
});

export default ProjectToDoList;