import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View, Text, TextInput } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { uploadData } from 'aws-amplify/storage';
import { useQuery } from '@tanstack/react-query';
import { useForm, Controller, useController } from 'react-hook-form';
import DropDownPicker from 'react-native-dropdown-picker';
import Toast from 'react-native-toast-message';

import { fetchOrgUsers } from '../../utils/getUserInfo';
import fetchProjects from '../../utils/getProjects.js'
import { UserContext } from '../../../App.js';

function Upload() {
    const user = useContext(UserContext)

    const cal = false
    const start = "2024-07-01"
    const end = "2025-03-31"

    var usersResult = {}
    var projectsResult = {}

    usersResult = useQuery({
        queryKey: ['organization', user.organization_id],
        queryFn: () => fetchOrgUsers(user.organization_id),
    })

    const [participantOptions, setParticipantOptions] = useState([])
    useEffect(() => {
        if ((usersResult.status === 'success') && (participantOptions.length === 0)) {
            setParticipantOptions(
                usersResult.data.map(
                    (user) => ({ label: user.username, value: user.id })
                ));
        }
    });

    projectsResult = useQuery({
        queryKey: ['projects', user.id, start, end],
        queryFn: () => fetchProjects(user.id, start, end),
    })

    const [projectOptions, setProjectOptions] = useState([])
    useEffect(() => {
        if ((projectsResult.status === 'success') && (projectOptions.length === 0)) {
            setProjectOptions(
                projectsResult.data.map(
                    (project) => ({ label: project.project_name, value: project.id })
                ));
        }
    });

    const { register, control, getValues, handleSubmit, formState: { errors } } = useForm(
        {
            defaultValues: {
                participants: [user.id],
                file: '',
            },
        }
    );

    const uploadSuccess = () => {
        Toast.show({
            type: 'success',
            text1: 'Upload completed.',
            text2: 'New tasks should be available in a minute or two.',
        });
    }

    const uploadFailure = () => {
        Toast.show({
            type: 'failure',
            text1: 'Something went wrong.',
            text2: 'Please try again or contact support.',
        });
    };

    const onSubmit = async (data) => {

        console.log(data);

        const task_name = data['task_name'];
        const file = data['file'][0];

        try {
            uploadData({
                path: `uploads/${user.username}/${file.name}`,
                data: file,
                options: {
                    metadata: {
                        participants: data['participants'],
                        project: task_name,
                    },
                },
            });
            uploadSuccess();
        } catch (error) {
            console.error(error);
            uploadFailure();
        }
    };

    return (
        <View style={{ flex: 1 }}>
            {((usersResult.status === 'pending') || (projectsResult.status === 'pending')) ? (
                <ActivityIndicator />
            ) : (

                <SafeAreaView style={styles.container}>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)} style={styles.formContainer}>
                            <input type="file" style={styles.input} {...register("file")} />
                            <ParticipantMultiSelect
                                control={control}
                                name="participants"
                                getValues={getValues}
                                participantOptions={participantOptions} />
                            <input type="submit" />
                        </form>
                    </div>
                </SafeAreaView>
            )}
        </View>
    );
}


function ParticipantMultiSelect({ control, name, getValues, participantOptions }) {
    const [open, setOpen] = useState(false);
    const { field } = useController({ control, name });
    const [items, setItems] = useState(participantOptions);

    return (
        <DropDownPicker
            open={open}
            value={field.value}
            items={participantOptions}
            setOpen={setOpen}
            setValue={cb => {
                const newValue = cb(field.value)
                field.onChange(newValue)
            }}
            setItems={setItems}
            theme="LIGHT"
            multiple={true}
            mode="BADGE"
            badgeDotColors={[
                '#e76f51',
                '#00b4d8',
                '#e9c46a',
                '#e76f51',
                '#8ac926',
                '#00b4d8',
                '#e9c46a',
            ]}
        />
    );
}

export default Upload;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#d3d3d3',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formContainer: {
        width: '100%',
        maxWidth: 300,
        alignItems: 'stretch',
    },
    input: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'gray',
        backgroundColor: 'white',
        padding: 10,
        marginBottom: 10,
    },
    buttons: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        alignSelf: 'center',
        justifyContent: 'space-around',
        width: '40%',

    },
});