import React, { useContext } from 'react';
import { ActivityIndicator, StyleSheet, FlatList, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from '@tanstack/react-query';
//import todos from './todos.json';
import fetchApiTodos from '../../utils/getAPITodos.js';
import fetchApiTodoDetails from '../../utils/getAPITodoDetails.js';
import { fetchUserInfo, fetchOrgUsers } from '../../utils/getUserInfo';
import fetchProjects from '../../utils/getProjects.js'
import { UserContext } from '../../../App.js';

import Task from './Task.js';

const ToDoList = ({ }) => {

    const user = useContext(UserContext)

    const id_type = "user"
    const id_value = user.id

    const cal = false
    const start = "2024-07-01"
    const end = "2025-03-31"

    var projectsResult = []
    var usersResult = []

    var result = { "status": "pending" }
    var detailsResult = {}

    result = useQuery({
        queryKey: ['todos', id_type, id_value, start, end, cal,],
        queryFn: () => fetchApiTodos(id_type, id_value, start, end, cal,),
    })

    detailsResult = useQuery({
        queryKey: ['details', id_type, id_value, start, end, cal],
        queryFn: () => fetchApiTodoDetails(id_type, id_value, start, end, cal),
    })

    projectsResult = useQuery({
        queryKey: ['projects', id_value, start, end],
        queryFn: () => fetchProjects(id_value, start, end),
    })

    usersResult = useQuery({
        queryKey: ['organization', user.organization_id],
        queryFn: () => fetchOrgUsers(user.organization_id),
    })

    const renderTask = ({ item }) => {
        return (
            <Task
                user_id={id_value}
                owner_code={item.owner_code}
                owner_icon_color={item.owner_icon_color}
                task_id={item.task_id}
                task_name={item.task_name}
                project_name={item.project_name}
                due_date={item.due_date}
                completion_percent={item.completion_percent}
                completion_target={item.completion_target}
                detailsStatus={detailsResult.status}
                details={detailsResult.data}
                projectStatus={projectsResult.status}
                projects={projectsResult.data}
                usersStatus={usersResult.status}
                users={usersResult.data}
            />
        );
    };

    return (
        <View style={{ flex: 1 }}>
            {result.status === 'pending' ? (
                <ActivityIndicator />
            ) : (
                <SafeAreaView style={styles.container}>
                    <FlatList style={styles.flatList}
                        data={result.data}
                        renderItem={renderTask}
                    />

                </SafeAreaView>
            )}
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#ffffff',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flatList: {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
    },
});

export default ToDoList;