import React, { useEffect, useState, createContext } from 'react';
import { ActivityIndicator, StyleSheet, Text, View, Image, Button } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

import { Amplify } from 'aws-amplify';
import { fetchUserAttributes } from 'aws-amplify/auth';
import {
  Authenticator,
  useAuthenticator,
  useTheme,
} from '@aws-amplify/ui-react-native';
import Toast from 'react-native-toast-message';

import { fetchUserInfo } from './src/utils/getUserInfo';

import outputs from './amplify_outputs.json';
Amplify.configure(outputs);


import ToDoTab from './src/components/Tasks/ToDoTab';
import ExpandableCalendarTab from "./src/components/Calendar/ExpandableCalendarTab";
import Upload from "./src/components/Upload/Upload";
//import Call from './src/components/Call/Call';

const UserContext = createContext();

function HomeScreen() {
  return (
    <SafeAreaView style={styles.container}>
      <Text>Home!</Text>
      <Image
        style={{
          resizeMode: 'contain',
          height: 100,
          width: 200,
        }}
        source={require('/src/assets/images/menu.svg')}
      />
    </SafeAreaView>
  );
}

function SignOutButton() {
  const { signOut } = useAuthenticator();
  return <Button onPress={signOut} title="Sign Out" />;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const Tab = createMaterialTopTabNavigator();

const MyAppHeader = () => {
  const {
    tokens: { space, fontSizes },
  } = useTheme();
  return (
    <View>
      <Text style={{ fontSize: fontSizes.xxxl, padding: space.xl }}>
        My Header
      </Text>
    </View>
  );
};


function NavTabs() {

  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchUserInfo().then(myUser => {
      setUser(user => ({ ...myUser }));
    })
  }, []);

  return (
    <View style={{ flex: 1 }}>
      {user == null ? (
        <ActivityIndicator />
      ) : (
        <UserContext.Provider value={user}>
          <Tab.Navigator
            initialRouteName="ToDoList"
            //tabBarPosition="bottom"
            screenOptions={{
              tabBarActiveTintColor: '#e91e63',
              tabBarLabelStyle: { fontSize: 12 },
              tabBarStyle: { backgroundColor: 'darkblue' },
              tabBarActiveTintColor: 'white',
              tabBarIndicatorStyle: {
                backgroundColor: 'white',
                height: 2
              },
              tabBarShowIcon: true,
              tabBarShowLabel: false,
            }}
          >
            <Tab.Screen
              name="Menu"
              component={HomeScreen}
              initialParams={{ userData: user }}
              options={{
                tabBarLabel: 'Menu',
                tabBarIcon: ({ }) => (
                  <Image
                    style={{
                      resizeMode: 'contain',
                      height: 24,
                      width: 24,
                    }}
                    source={require('/src/assets/images/menu.svg')}
                  />
                ),
              }}
            />
            <Tab.Screen
              name="ToDoList"
              component={ToDoTab}
              initialParams={{ userData: user }}
              options={{
                tabBarLabel: 'ToDo List',
                tabBarIcon: ({ }) => (
                  <Image
                    style={{
                      resizeMode: 'contain',
                      height: 24,
                      width: 24,
                    }}
                    source={require('/src/assets/images/inventory.svg')}
                  />
                ),
              }}
            />
            <Tab.Screen
              name="Calendar"
              component={ExpandableCalendarTab}
              initialParams={{ userData: user }}
              options={{
                tabBarLabel: 'Calendar',
                tabBarIcon: ({ }) => (
                  <Image
                    style={{
                      resizeMode: 'contain',
                      height: 24,
                      width: 24,
                    }}
                    source={require('/src/assets/images/calendar_month.svg')}
                  />
                ),
              }}
            />
            <Tab.Screen
              name="Contacts"
              component={HomeScreen}
              initialParams={{ userData: user }}
              options={{
                tabBarLabel: 'Contacts',
                tabBarIcon: ({ }) => (
                  <Image
                    style={{
                      resizeMode: 'contain',
                      height: 24,
                      width: 24,
                    }}
                    source={require('/src/assets/images/group.svg')}
                  />
                ),
              }}
            />
            <Tab.Screen
              name="Chats"
              component={Upload}
              initialParams={{ userData: user }}
              options={{
                tabBarLabel: 'Chats',
                tabBarIcon: ({ }) => (
                  <Image
                    style={{
                      resizeMode: 'contain',
                      height: 24,
                      width: 24,
                    }}
                    source={require('/src/assets/images/forum.svg')}
                  />
                ),
              }}
            />
          </Tab.Navigator>
        </UserContext.Provider>

      )
      }
      <Toast />
    </View >
  );
};

export default function App() {
  const {
    tokens: { colors },
  } = useTheme();

  return (
    <Authenticator.Provider>
      <Authenticator
        // will wrap every subcomponent
        Container={(props) => (
          // reuse default `Container` and apply custom background
          <Authenticator.Container
            {...props}
            style={{ backgroundColor: colors.pink[20] }}
          />
        )}
        // will render on every subcomponent
        Header={MyAppHeader}

      >
        <SafeAreaProvider>
          <NavigationContainer>
            <SafeAreaView>
              <SignOutButton />
            </SafeAreaView>
            <QueryClientProvider client={queryClient}>
              <NavTabs />
            </QueryClientProvider>
          </NavigationContainer>
        </SafeAreaProvider>
      </Authenticator>
    </Authenticator.Provider>
  );
}

export { UserContext };

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#d3d3d3',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    flex: 1,
    paddingTop: 58,
  },
  image: {
    width: 320,
    height: 440,
    borderRadius: 18,
  },
  footerContainer: {
    flex: 1 / 3,
    alignItems: 'center',
  },
  optionsContainer: {
    position: 'absolute',
    bottom: 80,
  },
  optionsRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});